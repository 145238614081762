import { combineReducers } from 'redux';
import { UserTypes } from '../../action_types';

import { handleRequest, initialRequestState } from './helpers';

function getUsers(state = initialRequestState(), action) {
  return handleRequest(
    UserTypes.GET_USERS_REQUEST,
    UserTypes.GET_USERS_SUCCESS,
    UserTypes.GET_USERS_FAIL,
    state,
    action
  );
}

function getOrgUsers(state = initialRequestState(), action) {
  return handleRequest(
    UserTypes.GET_ORG_USERS_REQUEST,
    UserTypes.GET_ORG_USERS_SUCCESS,
    UserTypes.GET_ORG_USERS_FAIL,
    state,
    action
  );
}

function getUser(state = initialRequestState(), action) {
  return handleRequest(UserTypes.GET_USER_REQUEST, UserTypes.GET_USER_SUCCESS, UserTypes.GET_USER_FAIL, state, action);
}

function createUser(state = initialRequestState(), action) {
  return handleRequest(
    UserTypes.CREATE_USER_REQUEST,
    UserTypes.CREATE_USER_SUCCESS,
    UserTypes.CREATE_USER_FAIL,
    state,
    action
  );
}

function updateUser(state = initialRequestState(), action) {
  return handleRequest(
    UserTypes.UPDATE_USER_REQUEST,
    UserTypes.UPDATE_USER_SUCCESS,
    UserTypes.UPDATE_USER_FAIL,
    state,
    action
  );
}

function updateOrgUser(state = initialRequestState(), action) {
  return handleRequest(
    UserTypes.UPDATE_ORG_USER_REQUEST,
    UserTypes.UPDATE_ORG_USER_SUCCESS,
    UserTypes.UPDATE_ORG_USER_FAIL,
    state,
    action
  );
}

function deleteUser(state = initialRequestState(), action) {
  return handleRequest(
    UserTypes.DELETE_USER_REQUEST,
    UserTypes.DELETE_USER_SUCCESS,
    UserTypes.DELETE_USER_FAIL,
    state,
    action
  );
}

function importStaffsExcel(state = initialRequestState(), action) {
  return handleRequest(
    UserTypes.IMPORT_STAFFS_EXCEL_REQUEST,
    UserTypes.IMPORT_STAFFS_EXCEL_SUCCESS,
    UserTypes.IMPORT_STAFFS_EXCEL_FAIL,
    state,
    action
  );
}

function exportStaffsExcel(state = initialRequestState(), action) {
  return handleRequest(
    UserTypes.EXPORT_STAFFS_EXCEL_REQUEST,
    UserTypes.EXPORT_STAFFS_EXCEL_SUCCESS,
    UserTypes.EXPORT_STAFFS_EXCEL_FAIL,
    state,
    action
  );
}

function changePassword(state = initialRequestState(), action) {
  return handleRequest(
    UserTypes.CHANGE_PASSWORD_REQUEST,
    UserTypes.CHANGE_PASSWORD_SUCCESS,
    UserTypes.CHANGE_PASSWORD_FAIL,
    state,
    action
  );
}

export default combineReducers({
  getUsers,
  getOrgUsers,
  getUser,
  createUser,
  updateUser,
  updateOrgUser,
  deleteUser,
  importStaffsExcel,
  exportStaffsExcel,
  changePassword,
});
