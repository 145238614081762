import moment from 'moment';

export function objectToArray(obj) {
  if (!obj) return [];
  return Object.keys(obj).map(key => {
    return obj[key];
  });
}

export function arrayToObject(arr) {
  if (!arr) return {};

  let obj = {};
  for (let i = 0; i < arr.length; i++) {
    obj[arr[i].id] = arr[i];
  }

  return obj;
}

export function getBase64(img, callback) {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result));
  reader.readAsDataURL(img);
}

export function formatFloors(str) {
  if (!str) return [];

  let arr = [];
  const floorsArr = str
    .trim()
    .trim()
    .split(/[ :;\(,\).]+/);
  const length = floorsArr.length;
  for (let i = 0; i < length; i++) {
    const element = floorsArr[i];

    if (element.indexOf('-') !== -1) {
      const subArr = element.split('-');
      for (let e = parseInt(subArr[0]); e <= parseInt(subArr[1]); e++) {
        arr.push(e);
      }
    } else {
      arr.push(parseInt(element));
    }
  }
  return arr;
}

export function removeItem(array, id) {
  return array.filter(item => item.id !== id);
}

export function insertItem(array, item) {
  let newArray = array.slice();
  newArray.splice(0, 0, item);
  return newArray;
}

export function updateObjectInArray(array, obj) {
  return array.map(item => {
    if (item.id !== obj.id) {
      return item;
    }

    return {
      ...item,
      ...obj,
    };
  });
}

export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function trimProperty(form) {
  return Object.keys(form).reduce((newForm, key) => {
    const value = form[key];
    if (typeof value === 'string') {
      return { ...newForm, [key]: value.trim() };
    }
    return { ...newForm, [key]: value };
  }, {});
}

export function convertFloors(floors) {
  const arrays = floors
    .split(',')
//    .map(floor => parseInt(floor))
    .sort(function (a, b) {
      return a - b;
    })
    .reduce((r, n) => {
      const lastSubArray = r[r.length - 1];
      if (!lastSubArray || lastSubArray[lastSubArray.length - 1] !== n - 1) {
        r.push([]);
      }

      r[r.length - 1].push(n);

      return r;
    }, []);

  const newArr = arrays.flatMap(arr => {
    if (arr.length < 3) {
      return arr.map(item => item);
    } else {
      return `${arr[0]}-${arr[arr.length - 1]}`;
    }
  });

  return newArr.join(', ');
}

export function range(start, end) {
  const result = [];
  for (let i = start; i < end; i++) {
    result.push(i);
  }
  return result;
}

export function disabledTime(current) {
  return {
    disabledHours: () => {
      if (current > moment().endOf('day')) return [];
      return range(0, 24).filter(hour => hour < parseInt(moment().format('H')));
    },
    disabledMinutes: () => {
      if (current > moment()) return [];
      return range(0, 60).filter(min => min < parseInt(moment().format('m')));
    },
  };
}
