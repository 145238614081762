export default {
  GET_BUILDINGS_REQUEST: 'GET_BUILDINGS_REQUEST',
  GET_BUILDINGS_SUCCESS: 'GET_BUILDINGS_SUCCESS',
  GET_BUILDINGS_FAIL: 'GET_BUILDINGS_FAIL',

  GET_BUILDING_REQUEST: 'GET_BUILDING_REQUEST',
  GET_BUILDING_SUCCESS: 'GET_BUILDING_SUCCESS',
  GET_BUILDING_FAIL: 'GET_BUILDING_FAIL',

  UPDATE_BUILDING_REQUEST: 'UPDATE_BUILDING_REQUEST',
  UPDATE_BUILDING_SUCCESS: 'UPDATE_BUILDING_SUCCESS',
  UPDATE_BUILDING_FAIL: 'UPDATE_BUILDING_FAIL',

  CREATE_BUILDING_REQUEST: 'CREATE_BUILDING_REQUEST',
  CREATE_BUILDING_SUCCESS: 'CREATE_BUILDING_SUCCESS',
  CREATE_BUILDING_FAIL: 'CREATE_BUILDING_FAIL',

  DEACTIVATE_BUILDING_REQUEST: 'DEACTIVATE_BUILDING_REQUEST',
  DEACTIVATE_BUILDING_SUCCESS: 'DEACTIVATE_BUILDING_SUCCESS',
  DEACTIVATE_BUILDING_FAIL: 'DEACTIVATE_BUILDING_FAIL',

  ACTIVATE_BUILDING_REQUEST: 'ACTIVATE_BUILDING_REQUEST',
  ACTIVATE_BUILDING_SUCCESS: 'ACTIVATE_BUILDING_SUCCESS',
  ACTIVATE_BUILDING_FAIL: 'ACTIVATE_BUILDING_FAIL',

  GET_LOGO_REQUEST: 'GET_LOGO_REQUEST',
  GET_LOGO_SUCCESS: 'GET_LOGO_SUCCESS',
  GET_LOGO_FAIL: 'GET_LOGO_FAIL',

  UPLOAD_LOGO_REQUEST: 'UPLOAD_LOGO_REQUEST',
  UPLOAD_LOGO_SUCCESS: 'UPLOAD_LOGO_SUCCESS',
  UPLOAD_LOGO_FAIL: 'UPLOAD_LOGO_FAIL',

  CHANGE_BUILDING: 'CHANGE_BUILDING',
};
